import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Aktualita from "./Aktualita";
import Button from "react-bootstrap/Button";

class AktualityList extends React.Component {
  constructor(props) {
    super(props);
    let aktualityReshaped = [];
    while (props.aktuality.length)
      aktualityReshaped.push(props.aktuality.splice(0, 2));
    this.state = {
      aktuality: aktualityReshaped,
      showMoreStep: props.showMoreStep,
      rowsLimit: props.rowsLimit,
      page: 0
    };
  }

  generateRow = arr => {
    return (
      <Row>
        {arr.map(el => (
          <Col md="6">
            <Aktualita
              title={el.Title}
              text={el.Text}
              publishedDate={new Date(el.date).toLocaleString("cs-CZ", {
                year: "numeric",
                month: "numeric",
                day: "numeric"
              })}
            />
          </Col>
        ))}
      </Row>
    );
  };

  increaseRowsLimit = () => {
    this.setState({
      rowsLimit: this.state.rowsLimit + this.state.showMoreStep
    });
  };

  render() {
    const fromIndex = this.state.page * this.state.rowsLimit;
    const toIndex =
      this.state.page * this.state.rowsLimit + this.state.rowsLimit;
    return (
      <>
        <h1>Aktuality</h1>
        {this.state.aktuality
          .slice(fromIndex, toIndex)
          .map(arr => this.generateRow(arr))}
        {this.state.rowsLimit < this.state.aktuality.length && (
          <Row>
            <Col>
              <Button onClick={this.increaseRowsLimit}>Ukázat více</Button>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default AktualityList;
