import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./custom.css";
import Navigation from "./components/Navigation.js";
//import Routes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { Redirect, Route, Switch } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";

// Ugly hack to initialize global API
import API from "./Api/Api.js";
import Page from "./pages/Page";
import AktualityPage from "./pages/AktualityPage";
import HomePage from "./pages/HomePage";
import FotoPage from "./pages/FotoPage";
import FotoPagePrevious from "./pages/FotoPagePrevious";
import StylePage from "./pages/StylePage";
import AlbumPage from "./pages/AlbumPage";

global.api = API;
global.pages = "";

class NotFound extends React.Component {
  render() {
    return <Redirect to="/" />;
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      pages: ""
    };
  }

  componentDidMount() {
    global.api.get("/pages").then(res => this.setState({ pages: res.data }));
  }

  render() {
    //console.log(this.state.pages);
    global.pages = this.state.pages;
    return (
      <div className="App">
        <SimpleReactLightbox>
          <Router>
            <Navigation />
            <Switch>
              <Route path="/" exact render={() => <HomePage />} />
              <Route path="/aktuality" exact render={() => <AktualityPage />} />
              <Route
                path="/dokumenty"
                exact
                render={() => <Page pageId="14" />}
              />
              <Route
                path="/kontakt"
                exact
                render={() => <Page pageId="13" />}
              />
              <Route path="/fotogalerie" exact render={() => <FotoPage />} />
              <Route
                path="/fotogalerie/minule"
                exact
                render={() => <FotoPagePrevious />}
              />
              <Route path="/album/:title" exact component={AlbumPage} />
              <Route
                path="/zakladni-udaje-o-ms"
                exact
                render={() => <Page pageId="1" />}
              />
              <Route
                path="/charakteristika-ms"
                exact
                render={() => <Page pageId="2" />}
              />
              <Route
                path="/zapis-do-ms"
                exact
                render={() => <Page pageId="3" />}
              />
              <Route
                path="/nase-tridy"
                exact
                render={() => <Page pageId="4" />}
              />
              <Route path="/platby" exact render={() => <Page pageId="5" />} />
              <Route
                path="/stravovani"
                exact
                render={() => <Page pageId="6" />}
              />
              {/*<Route path="/gdpr" exact render={() => <Page pageId="7" />} /> */}
              <Route path="/stylepage" exact render={() => <StylePage />} />

              {/* Finally, catch all unmatched routes */}
              <Route component={NotFound} />
            </Switch>
          </Router>
        </SimpleReactLightbox>
        <footer className="text-center footer mt-auto py-3 text-dark">
          <hr className="hr-short" />
          <div className="container">
            <small>
              &copy; {new Date().getFullYear()}, Created by{" "}
              <a href="https://www.safio.cz/">Safio.cz</a>
            </small>
          </div>
        </footer>
      </div>
    );
  }
}

export default App;
