import React from "react";
//import Container from "react-bootstrap/Container";
import { Redirect } from "react-router-dom";

class HomePage extends React.Component {
  render() {
    //return <Container className="page">Home page</Container>;
    return <Redirect to="/aktuality" />;
  }
}

export default HomePage;
