import React from "react";
import Container from "react-bootstrap/Container";
import PhotoGallery from "../components/PhotoGallery";
import { Link } from "react-router-dom";

class FotoPage extends React.Component {
  constructor(props) {
    super(props);
    const albumBucketName = "sf-s3-mssterboholy";

    // Initialize the Amazon Cognito credentials provider
    window["AWS"].config.region = "eu-central-1"; // Region
    window["AWS"].config.credentials = new window[
      "AWS"
    ].CognitoIdentityCredentials({
      IdentityPoolId: "eu-central-1:3aa5aee6-838b-4f2d-8cec-9b333c23e45d"
    });

    // Create a new service object
    const s3 = new window["AWS"].S3({
      apiVersion: "2006-03-01",
      params: { Bucket: albumBucketName }
    });
    this.state = { s3: s3 };
  }

  render() {
    return (
      <Container className="page">
        <h1>Fotogalerie</h1>
        <PhotoGallery
          s3={this.state.s3}
          yearregexp={/^20(24(09|10|11|12)|25(01|02|03|04|05|06|07|08))/}
        />
        <Link to="/fotogalerie/minule">Fotogalerie minulého školního roku</Link>
      </Container>
    );
  }
}

export default FotoPage;
