import { Card } from "react-bootstrap";
import React from "react";

class PhotoAlbumThumbnail extends React.Component {
  constructor(props) {
    super(props);
    this.state = { thumbnail: null, albumBucketName: "sf-s3-mssterboholy" };
  }

  componentDidMount() {
    this.getPhotos(this.props.s3);
  }

  getPhotosInner = (err, data) => {
    if (err) {
      console.log("There was an error viewing your album: " + err.message);
    }
    // This is normaly dynamically loaded but we do not know how to handle this in innerfunction
    // 'this' references the AWS.Response instance that represents the response
    const href = "https://s3.eu-central-1.amazonaws.com/";
    let bucketUrl = href + this.state.albumBucketName + "/";

    let photos = data.Contents.map(photo => {
      return bucketUrl + encodeURIComponent(photo.Key);
    });
    this.setState({ thumbnail: photos[0] });
  };

  getPhotos = s3 => {
    const albumPhotosKey = this.props.title_full + "/thumb-";
    s3.listObjects({ Prefix: albumPhotosKey, MaxKeys: 1 }, this.getPhotosInner);
  };

  render() {
    if (this.state.thumbnail) {
      return (
        <Card>
          <Card.Img variant="top" src={this.state.thumbnail} loading="lazy" />
          <Card.Body>
            <Card.Title className="album-title">{this.props.title}</Card.Title>
          </Card.Body>
        </Card>
      );
    } else {
      return <></>;
    }
  }
}

export default PhotoAlbumThumbnail;
