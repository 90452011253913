import React from "react";
import Container from "react-bootstrap/Container";
import PhotoAlbum from "../components/PhotoAlbum";

class AlbumPage extends React.Component {
  constructor(props) {
    super(props);
    const albumBucketName = "sf-s3-mssterboholy";

    // Initialize the Amazon Cognito credentials provider
    window["AWS"].config.region = "eu-central-1"; // Region
    window["AWS"].config.credentials = new window[
      "AWS"
    ].CognitoIdentityCredentials({
      IdentityPoolId: "eu-central-1:3aa5aee6-838b-4f2d-8cec-9b333c23e45d"
    });

    // Create a new service object
    const s3 = new window["AWS"].S3({
      apiVersion: "2006-03-01",
      params: { Bucket: albumBucketName }
    });
    this.state = { s3: s3 };
  }

  render() {
    const regex = /^[0-9]+_?[0-9]+_?[0-9]+_/g;
    const title = this.props.match.params.title
      .replace(regex, "")
      .replaceAll("-", " ");
    return (
      <Container className="page">
        <h1>{title}</h1>
        <PhotoAlbum s3={this.state.s3} title={this.props.match.params.title} />
      </Container>
    );
  }
}

export default AlbumPage;
