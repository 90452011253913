import React from "react";
import { SRLWrapper } from "simple-react-lightbox";

// USE THE IMPORT BELOW INSTEAD IF YOU ARE USING THE PRO VERSION
// import { SRLWrapper } from 'simple-react-lightbox-pro'

class PhotoAlbum extends React.Component {
  constructor(props) {
    super(props);
    this.state = { photos: [], albumBucketName: "sf-s3-mssterboholy" };
  }

  getPhotosInner = (err, data) => {
    if (err) {
      console.log("There was an error viewing your album: " + err.message);
    }
    // This is normaly dynamically loaded but we do not know how to handle this in innerfunction
    // 'this' references the AWS.Response instance that represents the response
    const href = "https://s3.eu-central-1.amazonaws.com/";
    let bucketUrl = href + this.state.albumBucketName + "/";
    let photos = data.Contents.map(photo => {
      return bucketUrl + encodeURIComponent(photo.Key);
    });
    this.setState({ photos: photos });
  };

  getPhotos = s3 => {
    const albumPhotosKey = this.props.title + "/thumb-";
    s3.listObjects({ Prefix: albumPhotosKey }, this.getPhotosInner);
  };

  componentDidMount() {
    this.getPhotos(this.props.s3);
  }

  render() {
    return (
      <div className="card-columns gallery_content">
        {this.state.photos.length === 0 ? (
          <p>Načítám fotografie</p>
        ) : (
          <SRLWrapper>
            {this.state.photos.map(photo => (
              <a href={photo.replace("thumb-", "")} className="card">
                <img
                  src={photo}
                  alt=""
                  className="card-img-top"
                  loading="lazy"
                />
              </a>
            ))}
          </SRLWrapper>
        )}
      </div>
    );
  }
}

export default PhotoAlbum;
