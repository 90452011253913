import React from "react";
import Container from "react-bootstrap/Container";
import ReactMarkdown from "react-markdown";

class StylePage extends React.Component {
  render() {
    const markdown = `
# Nadpis 1   
Lorem ipsum dolor sit amet ...

## Nadpis 2
Lorem ipsum dolor sit amet ...

### Nadpis 3
Lorem ipsum dolor sit amet ...

#### Nadpis 4
Lorem ipsum dolor sit amet ...

##### Nadpis 5
Lorem ipsum dolor sit amet ...

###### Nadpis 6
Lorem ipsum dolor sit amet ...

Jedna radka  
druha radka  
treti radka

Odstavecek 1

Odstavecek 2

* Necislovany seznam
* pokracovani
* ... a dalsi
  
1. Cislovany seznam
2. pokracovani
3. ... a dalsi

A paragraph with *emphasis* and **strong importance**.

> A block quote with ~strikethrough~ and a URL: https://reactjs.org.

* Lists
* [ ] todo
* [x] done

A table:

| a | b |
| - | - |
| Test 1 | test 2
    `;

    return (
      <Container className="page">
        <h1>Style Page</h1>
        <ReactMarkdown source={markdown} escapeHtml={false} />
      </Container>
    );
  }
}

export default StylePage;
