import React from "react";
import PhotoAlbumThumbnail from "./PhotoAlbumThumbnail";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function chunk_array(inputArray, chunk_size) {
  return inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunk_size);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
}

class PhotoGallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      albums: [],
      yearregexp: props.yearregexp
    };
  }

  componentDidMount() {
    this.props.s3.listObjects({ Delimiter: "/" }, this.getAlbums);
  }

  getAlbums = (err, data) => {
    const regex = /^[0-9]+_?[0-9]+_?[0-9]+_/g;
    if (err) {
      console.log("There was an error listing your albums: " + err.message);
    } else {
      let albums = data.CommonPrefixes.map(function(commonPrefix) {
        let prefix = commonPrefix.Prefix;
        return prefix.replace("/", "");
      });
      const yearregexp = this.state.yearregexp;
      this.setState({
        albums: albums
          .sort()
          .reverse()
          .filter(function(val) {
            return val.match(yearregexp);
          })
          .map((album_name, i) => ({
            title_full: album_name,
            title: album_name.replace(regex, "").replaceAll("-", " ")
          }))
      });
      //console.log(this.state.albums);
    }
  };

  render_row = (album_row, row_nr) => {
    return (
      <>
        {album_row.map(album => (
          <Col key={album.title_full} xs="12" sm="6" md="4" lg="3" xl="3">
            <Link to={"/album/" + album.title_full}>
              <PhotoAlbumThumbnail
                key={album.title_full}
                title={album.title}
                title_full={album.title_full}
                s3={this.props.s3}
              />
            </Link>
          </Col>
        ))}
      </>
    );
  };

  render() {
    const chunked_albums = chunk_array(this.state.albums, 4);
    return (
      <Row>
        {chunked_albums.map(albums_chunk => this.render_row(albums_chunk))}
      </Row>
    );
  }
}

export default PhotoGallery;
