import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, withRouter } from "react-router-dom";

class Navigation extends React.Component {
  render() {
    return (
      <Navbar bg="light" expand="sm">
        <Navbar.Brand href="/" className="d-md-block d-none">
          <img
            src="/logo.png"
            width="150"
            height="90"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Brand href="/" className="d-block d-md-none">
          <img
            src="/logo.png"
            width="100"
            height="60"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link to="/aktuality" className="nav-link">
              Aktuality
            </Link>
            <NavDropdown title="Informace" id="Informace-nav-dropdown">
              <Link to="/charakteristika-ms" className="dropdown-item">
                Charakteristika MŠ
              </Link>
              <Link to="/zapis-do-ms" className="dropdown-item">
                Zápis do MŠ
              </Link>
              {/*<NavDropdown.Divider />*/}
              <Link to="/nase-tridy" className="dropdown-item">
                Naše třídy
              </Link>
              <Link to="/platby" className="dropdown-item">
                Platby
              </Link>
              <Link to="/stravovani" className="dropdown-item">
                Stravování
              </Link>
              {/*
              <Link to="/gdpr" className="dropdown-item">
                GDPR
              </Link>
              */}
            </NavDropdown>
            <Link to="/dokumenty" className="nav-link">
              Dokumenty
            </Link>
            <Link to="/kontakt" className="nav-link">
              Kontakt
            </Link>
            <Link to="/fotogalerie" className="nav-link">
              Fotogalerie
            </Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Brand href="/" className="d-lg-block d-none">
          <img
            src="/logo.png"
            width="150"
            height="90"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
      </Navbar>
    );
  }
}

export default withRouter(Navigation);
