import React from "react";
import ReactMarkdown from "react-markdown";

function Aktualita(props) {
  return (
    <>
      <h4>{props.title}</h4>
      <div>
        <ReactMarkdown source={props.text} escapeHtml={false} />
        <small>{props.publishedDate}</small>
      </div>
      <br />
    </>
  );
}

export default Aktualita;
