import React from "react";
import Container from "react-bootstrap/Container";
import ReactMarkdown from "react-markdown";

class Page extends React.Component {
  render() {
    //console.log(this.props.pageId);
    var tmp = undefined;
    if (typeof global.pages.find !== "undefined") {
      tmp = global.pages.find(
        el => parseInt(el.id) === parseInt(this.props.pageId)
      );
    }
    if (typeof tmp !== "undefined") {
      return (
        <Container className="page">
          <h1>{tmp.Title}</h1>
          <ReactMarkdown source={tmp.Text} escapeHtml={false} />
        </Container>
      );
    } else {
      return <Container className="page"></Container>;
    }
  }
}

export default Page;
