import React from "react";
import Container from "react-bootstrap/Container";
import AktualityList from "../components/AktualityList";

class AktualityPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { aktuality: null };
  }

  componentDidMount() {
    global.api.get("/aktualities?_sort=date:DESC").then(res => {
      this.setState({ aktuality: res.data }, () => console.log(this.state));
    });
  }

  render() {
    return (
      <Container className="page">
        {this.state.aktuality !== null && (
          <AktualityList
            aktuality={this.state.aktuality}
            rowsLimit={3}
            showMoreStep={2}
          />
        )}
      </Container>
    );
  }
}

export default AktualityPage;
